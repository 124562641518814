import React from "react"
import { RiDownload2Line } from "react-icons/ri"
import Layout from "../components/layout"
import { Title } from "../components/parts"

const Resume = () => {
  return (
    <Layout title="Resume">
      <Title heading="Resume" sub="">
        <a
          href="/resume.pdf"
          download={`eb-resume-${new Date().toISOString().split("T")[0]}.pdf`}
          className="border-link"
          style={{ color: "white", borderColor: "white" }}
        >
          <RiDownload2Line />
          &nbsp; Download as PDF
        </a>
      </Title>
      <div className="section-bottom" />
      <object
        data="/resume.pdf"
        type="application/pdf"
        style={{ width: "100%", height: "100vh" }}
      >
        <p>It appears you don't have a PDF plugin for this browser.</p>
      </object>
    </Layout>
  )
}

export default Resume
